<template>
  <div>
    <b-modal ref="warningDialog" title="User Settings Error" header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light" footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <b-modal ref="modalRef" @hidden="handleClose" :visible="userWorklistSettingsDialog" title="Worklist Settings"
      size="xl" scrollable header-bg-variant="secondary" header-text-variant="light" body-bg-variant="dark"
      body-text-variant="light" footer-bg-variant="dark" footer-text-variant="light" content-class="shadow">
      <template v-if="isSuperAdmin">
        <div class="mb-2">
          <b-dropdown :text="selectedCustomer && selectedCustomer.name ? selectedCustomer.name :
            'Select customer'
            " size="sm" block disabled class="dropdown user-administration-dropdown">
            <b-dropdown-item href="#" v-for="customer in allCustomers" :key="customer.id"
              @click="onSelectCustomer(customer)">{{ customer.name }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <b-tabs v-model="tabIndex" pills vertical @activate-tab="handleActivateTab">
        <b-tab disabled>
          <template #title><strong>Worklist</strong></template>
        </b-tab>
        <b-tab title="‣ Columns (Select)">
          <b-form>
            <b-row>
              <b-col>
                <div>
                  <b-alert variant="info" show>Select column names for worklist.</b-alert>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2" class="text-right">
                <b-form>
                  <div><strong>ALLOW</strong></div>
                  <b-form-checkbox @change="handleAllowAll" />
                  <b-checkbox-group id="worklistColumns" v-model="worklistColumnsAllowed"
                    @change="worklistColumnsChanged" stacked>
                    <b-form-checkbox v-for="field in fields" :key="field.key" :value="field.key"></b-form-checkbox>
                  </b-checkbox-group>
                </b-form>
              </b-col>
              <b-col cols="10">
                <b-form>
                  <div><strong>SHOW</strong></div>
                  <b-form-checkbox @change="handleShowAll"><strong>Select/unselect all</strong></b-form-checkbox>
                  <b-checkbox-group id="worklistColumns" v-model="worklistColumnsSelected"
                    @change="worklistColumnsChanged" stacked>
                    <b-form-checkbox v-for="field in fields" :key="field.key" :value="field.key"
                      :disabled="!worklistColumnsAllowed.includes(field.key)">{{ field.label }}</b-form-checkbox>
                  </b-checkbox-group>
                </b-form>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="‣ Columns (Order)">
          <div>
            <b-alert variant="info" show>Drag column names to re-order how they appear in
              worklist.</b-alert>
          </div>
          <div class="slickDiv mt-2 ml-2">
            <SlickList lockAxis="y" v-model="worklistColumnsSorted" tag="div" @input="worklistColumnOrderChanged"
              appendTo=".slickDiv">
              <SlickItem v-for="(field, index) in worklistColumnsSorted" :index="index" :key="index" tag="div">
                {{ field.label }}
              </SlickItem>
            </SlickList>
          </div>
        </b-tab>
        <b-tab title="‣ Actions">
          <b-form>
            <div>
              <b-alert variant="info" show>Select action buttons for worklist. Only allowed if user has associated
                permission.</b-alert>
            </div>
            <div><strong>SHOW</strong></div>
            <div><b-form-checkbox @change="handleActionsAll"><strong>Select/unselect all</strong></b-form-checkbox></div>
            <b-checkbox-group id="worklistActions" v-model="worklistActionsSelected" @change="onValuesChange">
              <div v-for="action in actions" :key="action.key">
                <b-form-checkbox :value="action.key"
                  :disabled="!canUseAction(action.key)">
                  {{ action.label }}
                </b-form-checkbox>&nbsp;<b-badge v-if="!canUseAction(action.key)">{{ permissionsForAction(action.key) }}</b-badge>
              </div>
            </b-checkbox-group>
          </b-form>
        </b-tab>
        <b-tab title="‣ Refresh">
          <b-form>
            <div>
              <b-alert variant="info" show>Select interval for auto refresh.</b-alert>
            </div>
            <b-form-group label="Refresh Interval (in minutes):" label-for="refreshInterval" label-cols="6">
              <b-form-spinbutton id="refreshInterval" v-model="worklistRefreshInterval" min="1" max="30"
                @change="onValuesChange"></b-form-spinbutton>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab disabled>
          <template #title><strong>Viewers</strong></template>
        </b-tab>
        <b-tab title="‣ Options" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Select options for built-in viewers.</b-alert>
              <b-alert v-if="lockViewerOptions" variant="warning" show>Viewer options can only be updated from main
                window.</b-alert>
            </div>
            <b-row>
              <b-col cols="6" class="text-right"> Open Viewers in: </b-col>
              <b-col cols="6">
                <b-form-radio-group id="viewersOpenIn" v-model="worklistViewers.viewers_open_in"
                  @change="onValuesChange">
                  <b-form-radio value="worklistWindow" :disabled="lockViewerOptions">Worklist Window</b-form-radio>
                  <b-form-radio value="viewerWindow" :disabled="lockViewerOptions">Viewer Window</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">
                Viewer for Double-Click Action:
              </b-col>
              <b-col cols="6">
                <b-form-radio-group id="dblClickRoute" v-model="worklistViewers.dbl_click_route"
                  @change="onValuesChange">
                  <b-form-radio value="viewer" :disabled="lockViewerOptions">Full</b-form-radio>
                  <b-form-radio value="viewer_lite" :disabled="lockViewerOptions">Lite</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">Quality for Lite Viewer:</b-col>
              <b-col cols="6">
                <b-form-spinbutton id="liteQuality" v-model="worklistViewers.lite_quality" min="20" max="100"
                  @change="onValuesChange" :disabled="lockViewerOptions"></b-form-spinbutton>
                <b-form-text id="listQualityHelp">Lower more compression/faster.<br />Higher less
                  compression/slower.</b-form-text>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">Annotation Color for Lite Viewer:</b-col>
              <b-col cols="6">
                <b-form-radio-group id="annotationColor" v-model="worklistViewers.lite_annotation_color"
                  @change="onValuesChange">
                  <b-form-radio value="#85aed3" :disabled="lockViewerOptions"
                    style="color: #85aed3;">Blue</b-form-radio>
                  <b-form-radio value="green" :disabled="lockViewerOptions" style="color: green;">Green</b-form-radio>
                  <b-form-radio value="lime" :disabled="lockViewerOptions" style="color: lime;">Lime</b-form-radio>
                  <b-form-radio value="orange" :disabled="lockViewerOptions"
                    style="color: orange;">Orange</b-form-radio>
                  <b-form-radio value="white" :disabled="lockViewerOptions" style="color: white;">White</b-form-radio>
                  <b-form-radio value="yellow" :disabled="lockViewerOptions"
                    style="color: yellow;">Yellow</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">Measurement Units for Full Viewer:</b-col>
              <b-col cols="6">
                <b-form-radio-group id="measurementUnits" v-model="worklistViewers.measurement_units"
                  @change="onValuesChange">
                  <b-form-radio value="cm" :disabled="lockViewerOptions">Centimeters (cm)</b-form-radio>
                  <b-form-radio value="mm" :disabled="lockViewerOptions">Millimeters (mm)</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6" class="text-right">Cache size for Full Viewer:</b-col>
              <b-col cols="6">
                <b-form-spinbutton id="cacheSize" v-model="worklistViewers.cache_size" min="0.5" max="16" step="0.25"
                  @change="onValuesChange" :disabled="lockViewerOptions"></b-form-spinbutton>
                <b-form-text id="cacheSizeHelp">Size in GB.<br /><b-icon icon="exclamation-triangle-fill"></b-icon>
                  High values may lead to performance issues.</b-form-text>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="‣ Alternative" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Select alternative viewers to launch (must be installed).<br />
                <b-badge variant="warning" show>Some configuration may be required in alternative viewer for
                  it to be able to access image data.</b-badge>
              </b-alert>
            </div>
            <b-checkbox-group id="worklistAltViewers" v-model="worklistAltViewersSelected" @change="onValuesChange">
              <div v-for="viewer in viewers" :key="viewer.key" >
                <b-form-checkbox v-if="viewer.enabled" :value="viewer.key" :disabled="!canViewImages">
                  {{ viewer.label }}
                </b-form-checkbox>&nbsp;<b-badge v-if="!canViewImages">{{ permissionsForAction('viewer_lite') }}</b-badge>
                <template v-for="help in viewer.help">
                  <b-form-text v-if="viewer.enabled" :key="help.key">{{ help.text }}
                    <a :href="help.url" target="_blank">{{ help.url }}</a>
                  </b-form-text>
                </template>
              </div>
            </b-checkbox-group>
          </b-form>
        </b-tab>
        <b-tab title="‣ Window/Level" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Predefined window-level settings for built-in viewers.
                <b-button variant="secondary" title="Add New" @click="wwwlFavoritesUseDefaults" size="sm">Use
                  Defaults</b-button>
              </b-alert>
            </div>
            <b-form-row>
              <b-col cols="5"> Description </b-col>
              <b-col cols="3"> Window Width </b-col>
              <b-col cols="3"> Window Level </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Add New" @click="wwwlFavoriteAdd"><b-icon
                    icon="plus-circle"></b-icon></b-button>
              </b-col>
            </b-form-row>
            <b-form-row v-for="key in wwwlFavoritesKeys" class="mt-1" :key="key">
              <b-col cols="5">
                <b-form-input v-model="wwwlFavoritesDescriptions[key]" @change="wwwlFavoriteChanged" />
              </b-col>
              <b-col cols="3">
                <b-form-input v-model="wwwlFavoritesWindows[key]" type="number" @change="wwwlFavoriteChanged" />
              </b-col>
              <b-col cols="3">
                <b-form-input v-model="wwwlFavoritesLevels[key]" type="number" @change="wwwlFavoriteChanged" />
              </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Delete" @click="wwwlFavoriteDelete(key)"><b-icon
                    icon="trash"></b-icon></b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </b-tab>
        <b-tab disabled>
          <template #title><strong>Reports</strong></template>
        </b-tab>
        <b-tab title="‣ Options" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Select options for reports.</b-alert>
            </div>
            <b-row>
              <b-col cols="3"> Open reports in: </b-col>
              <b-col cols="9">
                <b-form-radio-group id="reportsOpenIn" v-model="worklistViewers.reports_open_in"
                  @change="onValuesChange">
                  <b-form-radio value="sidePanel">Side Panel</b-form-radio>
                  <b-form-radio value="window">Report Window</b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row v-if="canEditReports" class="mt-5">
              <b-col cols="3"> Auto-create Report: </b-col>
              <b-col cols="9">
                <b-form-radio-group id="autoCreateReport" v-model="reportSettings.auto_create" @change="onValuesChange">
                  <b-form-radio :value="true">On</b-form-radio>
                  <b-form-radio :value="false">Off</b-form-radio>
                </b-form-radio-group>
                <b-form-text>If enabled and a study has no reports, a draft report based
                  on default template will be created when Report Viewer is
                  opened.</b-form-text>
              </b-col>
            </b-row>
            <b-row v-if="canEditReports" class="mt-5">
              <b-col cols="3"> Auto-save Interval (in minutes): </b-col>
              <b-col cols="9"><b-form-spinbutton id="autoSaveInterval" v-model="reportSettings.auto_save" min="1"
                  max="30" @change="onValuesChange"></b-form-spinbutton>
                <b-form-text>Interval to auto-save report when opened in Report
                  Editor.</b-form-text>
              </b-col>
            </b-row>
            <b-row v-if="canSignReports" class="mt-5">
              <b-col cols="3">
                Current signature:
              </b-col>
              <b-col cols="9">
                <div v-if="signatureImageSrc == null">No signature image for current user.</div>
                <b-img v-if="signatureImageSrc != null" v-bind="signatureImageProps" :src="signatureImageSrc" fluid
                  rounded alt="Signature"></b-img>
              </b-col>
            </b-row>
            <b-row v-if="canSignReports" class="mt-2">
              <b-col>
                <b-form-group label="New Signature:" label-for="signature_image_file" label-cols="3">
                  <b-form-file v-model="signatureImageFile" id="signature_image_file"
                    :state="Boolean(signatureImageFile)" accept="image/jpeg, image/png, image/gif"
                    placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                  <b-form-text>File size limited to {{ uploadLimitMb }} MB.</b-form-text>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="canSignReports" class="mt-2">
              <b-col>
                <div class="float-right">
                  <b-button @click="uploadSignature"
                    :disabled="(signatureImageFile == null) || (signatureImageFile.size > uploadLimitMb * 1024 * 1024)"
                    variant="primary">Load New Signature</b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="‣ Auto-Correct" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Auto-correct options for reports.
              </b-alert>
            </div>
            <b-form-checkbox v-model="reportSettings.capitalize_sentences">
              Capitalize first letter of sentences
            </b-form-checkbox>
          </b-form>
        </b-tab>
        <b-tab title="‣ Auto-Replacements" lazy>
          <b-form>
            <div>
              <b-alert variant="info" show>Predefined automatic replacements for phrases.<br />
                <b-badge variant="warning">Phrases may only contain alphanumeric characters, no spaces
                  or punctuation.</b-badge>&nbsp;
                <b-badge variant="warning">Phrase matching is case-senstive.</b-badge>
              </b-alert>
            </div>
            <b-form-row>
              <b-col cols="3"> Phrase </b-col>
              <b-col cols="8"> Replacement </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Add New" @click="reportPhraseAdd"><b-icon
                    icon="plus-circle"></b-icon></b-button>
              </b-col>
            </b-form-row>
            <b-form-row v-for="key in reportPhraseKeys" class="mt-1" :key="key">
              <b-col cols="3">
                <b-form-input v-model="reportPhrasePhrases[key]" @change="reportPhraseChanged" />
              </b-col>
              <b-col cols="8">
                <b-form-textarea v-model="reportPhraseReplacements[key]" rows="2" @change="reportPhraseChanged" />
              </b-col>
              <b-col cols="1">
                <b-button variant="secondary" title="Delete" @click="reportPhraseDelete(key)"><b-icon
                    icon="trash"></b-icon></b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button @click="handleClose" :disabled="isLoading" variant="secondary">Cancel</b-button>
        <b-button @click="saveSetting" :disabled="isLoading || !isFormDirty" variant="primary">
          <b-icon v-if="isLoading" icon="circle-fill" animation="throb" font-scale="1"></b-icon>Done</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import permissions from '../common/permissions'
import permissionDefns from '../common/permissionDefns.json'
import worklistActions from '../common/worklistActions.json'
import worklistFields from '../common/worklistFields.json'
import worklistAltViewers from '../common/worklistAltViewers.json'
import wwwlDefaults from '../common/wwwlDefaults.json'
import { SlickList, SlickItem } from 'vue-slicksort'
import uuid from "uuid"

import userAdministrationWebServices from '../common/userAdministrationWebServices'

export default {
  name: 'userWorlistSettings',
  components: {
    SlickItem,
    SlickList
  },
  props: {
    userWorklistSettingsDialog: {
      type: Boolean,
      default: false,
    },
    selectedCustomerId: {
      type: String,
      default: null
    },
    userData: {
      type: Object,
      default: () => { }
    },
    userInfo: {
      type: Object,
      default: () => { }
    },
    userSignature: {
      type: Object,
      default: null
    },
    "lockViewerOptions": Boolean,
  },
  data() {
    return {
      canEditReports: false,
      canSignReports: false,
      canViewImages: false,
      isLoading: false,
      isFormDirty: false,
      actions: worklistActions.data,
      fields: worklistFields.data,
      viewers: worklistAltViewers.data,
      permissionDefns: permissionDefns.permissionsGroup,
      worklistActionsSelected: [],
      worklistAltViewersSelected: [],
      worklistColumnsAllowed: [],
      worklistColumnsSorted: [],
      worklistColumnsSelected: [],
      worklistRefreshInterval: 5,
      worklistViewers: {
        "dbl_click_route": "viewer",
        "lite_quality": 70,
        "lite_annotation_color": "#85aed3",
        "measurement_units": "mm",
        "reports_open_in": "sidePanel",
        "viewers_open_in": "worklistWindow",
        "cache_size": 2,
      },
      signature: null,
      signatureImageSrc: null,
      signatureImageFile: null,
      signatureImageProps: { blank: false, class: 'm1' },
      speechToTextOptIn: false,
      refreshWorklistOnClose: false,
      wwwlFavoritesKeys: [],
      wwwlFavoritesDescriptions: {},
      wwwlFavoritesWindows: {},
      wwwlFavoritesLevels: {},
      wwwlFavoritesDirty: false,
      reportPhraseKeys: [],
      reportPhrasePhrases: {},
      reportPhraseReplacements: {},
      reportPhraseDirty: false,
      reportSettings: {
        "auto_create": false,
        "auto_save": 5,
        "capitalize_sentences": false,
      },
      tabIndex: 0,
      warningMsg: "",
      wwwlFavorites: null,
      allCustomers: [],
      selectedCustomer: {},
      settingData: {}
    }
  },

  computed: {
    reportPhraseLut() {
      return this.settingData.reportPhraseLut
    },
    uploadLimitMb() {
      return this.settingData.uploadLimitMb;
    },
    isSuperAdmin() {
      return permissions.isSuperAdmin();
    },
  },
  watch: {
    userData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.loadCustomers()
          this.settingData = JSON.parse(JSON.stringify(val))
        }
      }
    },
    userInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.$log.debug("userInfo updated")
      }
    },
    userWorklistSettingsDialog(newval, /*oldval*/) {
      if (newval) {
        this.canEditReports = false
        this.canSignReports = false
        this.canViewImages = false
        if (this.userInfo.customers && this.userInfo.customers[this.selectedCustomerId]) {
          var tenantIds = Object.keys(this.userInfo.customers[this.selectedCustomerId].tenants)
          tenantIds.forEach(tenantId => {
            if (this.userInfo.customers[this.selectedCustomerId].tenants[tenantId].permissions.includes(permissions.CAN_EDIT_REPORTS)) {
              this.canEditReports = true
            }
            if (this.userInfo.customers[this.selectedCustomerId].tenants[tenantId].permissions.includes(permissions.CAN_SIGN_PRELIM) ||
              this.userInfo.customers[this.selectedCustomerId].tenants[tenantId].permissions.includes(permissions.CAN_SIGN_FINAL)) {
              this.canSignReports = true
            }
            if (this.userInfo.customers[this.selectedCustomerId].tenants[tenantId].permissions.includes(permissions.CAN_VIEW_IMAGES)) {
              this.canViewImages = true
            }
          })
        }
        this.$log.debug(`userWorklistSettingsDialog updated: canEditReports=${this.canEditReports} canSignReports=${this.canSignReports} canViewImages=${this.canViewImages}`)
      }
    }
  },
  methods: {
    permissionsForAction(key) {
      var permissions = ""
      var prependStr = ""
      const action = this.actions.find(o => o.key === key)
      if (action) {
        action.permissions.forEach(permission => {
          var permissionStr = "N/A"
          this.permissionDefns.forEach(group => {
            const defn = group.permissions.find(o => o.value === permission)
            if (defn) {
              permissionStr = defn.text
            }
          })
          permissions += prependStr + permissionStr
          prependStr = ", "
        })
      }
      return `Requires permission [${permissions}]`
    },
    canUseAction(key) {
      var canUseAction = false
      const action = this.actions.find(o => o.key === key)
      if (action) {
        if (this.userInfo.customers && this.userInfo.customers[this.selectedCustomerId]) {
          var tenantIds = Object.keys(this.userInfo.customers[this.selectedCustomerId].tenants)
          tenantIds.forEach(tenantId => {
            action.permissions.forEach(permission => {
              if (this.userInfo.customers[this.selectedCustomerId].tenants[tenantId].permissions.includes(permission)) {
                canUseAction = true
              }
            })
          })
        }
      }
      return canUseAction
    },
    onValuesChange() {
      this.isFormDirty = true
    },
    uploadSignature() {
      var signatureImageFileName = this.signatureImageFile.name
      var reader = new FileReader();
      var thisForReader = this
      reader.onerror = function (/*file*/) {
        Vue.$log.error("Error reading signature image file=" + signatureImageFileName)
        reader.abort();
      };

      reader.onload = function (/*file*/) {
        Vue.$log.debug("Read signature image file=" + signatureImageFileName)
        thisForReader.signature = { file: reader.result, fileName: signatureImageFileName }
        const blob = new Blob([reader.result])
        thisForReader.signatureImageSrc = URL.createObjectURL(blob);
        thisForReader.signatureImageFile = null;
        thisForReader.isFormDirty = true;
      }

      reader.readAsArrayBuffer(this.signatureImageFile);
    },
    async onSelectCustomer(customer) {
      if (customer.id != this.selectedCustomer.id) {
        try {
          const payload = {
            customer_id: customer.id,
            user_id: this.$route.query.userId
          }
          const response = await userAdministrationWebServices.fetchUserWorkList(payload)
          this.settingData = response
          this.show(response)
        } catch (error) {
          Vue.$log.error("Error while selecting customer ", error)
        }

      }
    },
    async loadCustomers() {
      try {
        const response = await userAdministrationWebServices.fetchAdminTenants();
        this.allCustomers = response;
        const selectedCustomer = this.allCustomers.find(item => item.id == this.selectedCustomerId)
        this.selectedCustomer = selectedCustomer
        this.show()
      } catch (error) {
        Vue.$log.error("Error while fetching customer", error)
      }
    },
    initWorklistColumnsSelected() {
      this.worklistColumnsSelected = []
      if (this.worklistColumnsSorted?.length > 0) {
        for (var i = 0; i < this.worklistColumnsSorted.length; i++) {
          this.worklistColumnsSelected.push(this.worklistColumnsSorted[i].key)
        }
        this.$log.debug(this.worklistColumnsSelected)
      }

    },
    initWorklistColumnsSorted() {
      this.worklistColumnsSorted = []
      if (this.worklistColumnsSelected?.length > 0) {
        for (var i = 0; i < this.worklistColumnsSelected.length; i++) {
          for (var j = 0; j < this.fields.length; j++) {
            if (this.fields[j].key == this.worklistColumnsSelected[i]) {
              if (this.worklistColumnsAllowed.includes(this.worklistColumnsSelected[i])) {
                this.worklistColumnsSorted.push(this.fields[j])
              }
              break;
            }
          }
        }
      }

    },
    show() {
      this.refreshWorklistOnClose = false
      this.worklistColumnsAllowed = this.settingData.adminSettings?.worklistColumnsAllowed ?? []
      if (this.worklistColumnsAllowed.length == 0) {
        this.fields.forEach(field => {
          this.worklistColumnsAllowed.push(field.key)
        })
      }
      this.worklistColumnsSelected = this.settingData.worklistColumns ?? []
      this.worklistActionsSelected = this.settingData.worklistActions ?? []
      this.initWorklistColumnsSorted()
      this.worklistAltViewersSelected = this.settingData.worklistAltViewers ?? []
      this.worklistRefreshInterval = this.settingData.worklistRefreshInterval ?? 5
      if (this.settingData.worklistViewers) {
        var keys = Object.keys(this.settingData.worklistViewers)
        var k = 0
        for (k = 0; k < keys.length; k++) {
          this.worklistViewers[keys[k]] = this.settingData.worklistViewers[keys[k]]
        }
      }

      this.wwwlFavoritesKeys = []
      this.wwwlFavoritesDescriptions = {}
      this.wwwlFavoritesWindows = {}
      this.wwwlFavoritesLevels = {}
      this.wwwlFavorites = this.settingData?.wwwlFavorites
      if (this.wwwlFavorites) {
        for (var f = 0; f < this.wwwlFavorites.length; f++) {
          const key = this.wwwlFavorites[f].key
          this.wwwlFavoritesKeys.push(key)
          this.wwwlFavoritesDescriptions[key] = this.wwwlFavorites[f].description
          this.wwwlFavoritesWindows[key] = this.wwwlFavorites[f].window
          this.wwwlFavoritesLevels[key] = this.wwwlFavorites[f].level
        }
      }

      this.wwwlFavoritesDirty = false
      this.reportPhraseKeys = []
      this.reportPhrasePhrases = {}
      this.reportPhraseReplacements = {}
      if (this.reportPhraseLut) {
        let phrases = Object.keys(this.reportPhraseLut)
        for (var p = 0; p < phrases.length; p++) {
          let key = uuid.v4()
          this.reportPhraseKeys.push(key)
          this.reportPhrasePhrases[key] = phrases[p]
          this.reportPhraseReplacements[key] = this.reportPhraseLut[phrases[p]]
        }
      }

      this.reportPhraseDirty = false
      this.reportSettings = { ...this.reportSettings, ...this.settingData.reportSettings }
      if (this.userSignature && this.userSignature.file) {
        const blob = new Blob([this.userSignature.file])
        this.signatureImageSrc = URL.createObjectURL(blob);
      }
      else {
        this.signatureImageSrc = null;
      }
      this.signatureImageFile = null;
      this.isFormDirty = false;
    },
    async saveSetting() {
      if (this.isFormDirty) {
        this.isLoading = true
        if (this.wwwlFavoritesDirty) {
          var wwwlFavorites = []
          for (var f = 0; f < this.wwwlFavoritesKeys.length; f++) {
            const key = this.wwwlFavoritesKeys[f]
            const window = parseFloat(this.wwwlFavoritesWindows[key])
            const level = parseFloat(this.wwwlFavoritesLevels[key])
            wwwlFavorites.push({
              key: key,
              description: this.wwwlFavoritesDescriptions[key],
              window: isNaN(window) ? 1 : window,
              level: isNaN(level) ? 0 : level,
            })
          }
          this.wwwlFavorites = wwwlFavorites
        }

        this.warningMsg = ""

        if (this.reportPhraseDirty) {
          var reportPhraseLut = {}
          for (var p = 0; p < this.reportPhraseKeys.length; p++) {
            const key = this.reportPhraseKeys[p]
            const phrase = this.reportPhrasePhrases[key].trim()
            if (/^[a-zA-Z0-9]+$/i.test(phrase) == false) {
              this.warningMsg += `Phrase=[${phrase}]: phrases must only contain alphanumeric characters.<br/>`
            }
            if (phrase in reportPhraseLut) {
              this.warningMsg += `Phrase=[${phrase}]: phrases should only be used once.<br/>`
            }
            const replacement = this.reportPhraseReplacements[key]
            if ((phrase.length > 0) && (replacement.length > 0)) {
              reportPhraseLut[phrase] = replacement
            }
          }

          if (this.warningMsg == "") {
            this.reportPhraseLut = reportPhraseLut
            this.$log.debug("Updating cache for reportPhraseLut")
          }
          else {
            this.warningMsg = "<strong>Reports: Auto-Replacements</strong><br/>" + this.warningMsg
            this.$refs.warningDialog.show()
            return
          }
          return false
        }
        const setting = {
          adminSettings: {
            worklistColumnsAllowed: this.worklistColumnsAllowed
          },
          worklistActions: this.worklistActionsSelected,
          worklistColumns: this.worklistColumnsSelected,
          worklistRefreshInterval: this.worklistRefreshInterval,
          worklistAltViewers: this.worklistAltViewersSelected,
          worklistViewers: this.worklistViewers,
          wwwlFavorites: this.wwwlFavorites,
          reportPhraseLut: this.reportPhraseLut,
          reportSettings: this.reportSettings,
          worklistSettings: {}
        }
        this.$emit('save-setting', setting)
        this.$emit('save-signature', this.signature)
        this.isLoading = false
      }
      this.$emit('close')
    },
    handleActionsAll(checked) {
      this.worklistActionsSelected = []
      if (checked) {
        this.actions.forEach(action => { 
          if (this.canUseAction(action.key)) {
            this.worklistActionsSelected.push(action.key) 
          }
        })
      }
      this.isFormDirty = true
    },
    handleAllowAll(checked) {
      this.worklistColumnsAllowed = []
      if (checked) {
        this.fields.forEach(field => { this.worklistColumnsAllowed.push(field.key) })
      }
      else {
        this.worklistColumnsAllowed = ['__NoNe__']
        this.worklistColumnsSelected = []
      }
      this.initWorklistColumnsSorted()
      this.isFormDirty = true
    },
    handleShowAll(checked) {
      this.worklistColumnsSelected = []
      if (checked) {
        this.fields.forEach(field => {
          if (this.worklistColumnsAllowed.includes(field.key)) {
            this.worklistColumnsSelected.push(field.key)
          }
        })
      }
      this.initWorklistColumnsSorted()
      this.isFormDirty = true
    },
    handleClose() {
      this.$emit('close')
    },
    handleActivateTab() {
      this.$refs['modalRef'].$refs.body.scrollTop = 0;
    },
    handleWarningOK() {
      // Keep same values, let user fix.
      //
      // this.$refs['modalRef'].show()
    },
    worklistColumnOrderChanged() {
      this.initWorklistColumnsSelected()
      this.isFormDirty = true
    },
    worklistColumnsChanged() {
      // Make sure any selected to show that are not allowed get unchecked.
      //
      this.worklistColumnsSelected = this.worklistColumnsSelected.filter(column => this.worklistColumnsAllowed.includes(column))
      this.initWorklistColumnsSorted()
      this.isFormDirty = true
    },
    wwwlFavoriteAdd() {
      const key = uuid.v4()
      this.wwwlFavoritesDescriptions[key] = "New"
      this.wwwlFavoritesWindows[key] = 1000
      this.wwwlFavoritesLevels[key] = 500
      this.wwwlFavoritesKeys.push(key)
      this.wwwlFavoritesDirty = true
      this.isFormDirty = true
    },
    wwwlFavoriteChanged(/*value*/) {
      this.wwwlFavoritesDirty = true
      this.isFormDirty = true
    },
    wwwlFavoriteDelete(key) {
      const index = this.wwwlFavoritesKeys.indexOf(key)
      if (index > -1) {
        this.wwwlFavoritesKeys = this.wwwlFavoritesKeys.filter(value => {
          return value != key;
        })
        delete this.wwwlFavoritesDescriptions[key]
        delete this.wwwlFavoritesWindows[key]
        delete this.wwwlFavoritesLevels[key]
        this.wwwlFavoritesDirty = true
        this.isFormDirty = true
      }
    },
    wwwlFavoritesUseDefaults() {
      this.wwwlFavoritesKeys = []
      this.wwwlFavoritesDescriptions = {}
      this.wwwlFavoritesWindows = {}
      this.wwwlFavoritesLevels = {}
      const wwwlFavorites = wwwlDefaults.data
      for (var f = 0; f < wwwlFavorites.length; f++) {
        const key = wwwlFavorites[f].key
        this.wwwlFavoritesKeys.push(key)
        this.wwwlFavoritesDescriptions[key] = wwwlFavorites[f].description
        this.wwwlFavoritesWindows[key] = wwwlFavorites[f].window
        this.wwwlFavoritesLevels[key] = wwwlFavorites[f].level
      }
      this.wwwlFavoritesDirty = true
      this.isFormDirty = true
    },
    reportPhraseAdd() {
      const key = uuid.v4()
      this.reportPhrasePhrases[key] = ""
      this.reportPhraseReplacements[key] = ""
      this.reportPhraseKeys.push(key)
      this.reportPhraseDirty = true
      this.isFormDirty = true
    },
    reportPhraseChanged() {
      this.reportPhraseDirty = true
      this.isFormDirty = true
    },
    reportPhraseDelete(key) {
      const index = this.reportPhraseKeys.indexOf(key)
      if (index > -1) {
        this.reportPhraseKeys = this.reportPhraseKeys.filter(value => {
          return value != key;
        })
        delete this.reportPhrasePhrases[key]
        delete this.reportPhraseReplacements[key]
        this.reportPhraseDirty = true
        this.isFormDirty = true
      }
    },


  }
};
</script>
<style scoped>
.custom-footer {
  position: sticky;
  bottom: -17px;
  width: 100%;
  z-index: 10;
}
</style>
